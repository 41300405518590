import React from "react"
import { navigate } from 'gatsby'
import { Router } from "@reach/router"
import Layout from "../components/layout"
import Cv from "../components/Cv/Cv"

const NoneSelected = () => {
  React.useEffect(() => {
    navigate('/cv/1')
  }, [])

  return (
      <div></div>
  )
}

const CvPage = () => {
  return (
    <Router>
      <Layout path="/cv">
        <NoneSelected default />
        <Cv path="/:slug" />
      </Layout>
    </Router>
  )
}

export default CvPage
